import React, { useState, useEffect } from 'react';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Box,
  Typography,
  TablePagination,
  TableSortLabel,
} from '@mui/material';
import axios from 'axios';
import { format } from 'date-fns';
import { useAuth } from './auth';

const CustomPaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{ flexShrink: 0, marginLeft: '2.5em' }}>
      <Button onClick={handleFirstPageButtonClick} disabled={page === 0}>
        First
      </Button>
      <Button onClick={handleBackButtonClick} disabled={page === 0}>
        Back
      </Button>
      <Button onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
        Next
      </Button>
      <Button onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
        Last
      </Button>
    </div>
  );
};

const PayoutOperations = () => {
  const [payouts, setPayouts] = useState([]);
  const [paypalPayouts, setPaypalPayouts] = useState([]);
  const [statusFilter, setStatusFilter] = useState('');
  const [dateFilter, setDateFilter] = useState('');
  const [ibanFilter, setIbanFilter] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [totalDonations, setTotalDonations] = useState(0);
  const { token } = useAuth();
  const apiUrl = process.env.REACT_APP_BASE_USER_SERVER;
  const [page, setPage] = useState(0);
  const [paypalPage, setPaypalPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [paypalRowsPerPage, setPaypalRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState('');
  const [orderDirection, setOrderDirection] = useState('asc');
  const [paypalStatusFilter, setPaypalStatusFilter] = useState('');
  const [paypalDateFilter, setPaypalDateFilter] = useState('');
  const [paypalEmailFilter, setPaypalEmailFilter] = useState('');
  const [donations, setDonations] = useState([]);
  const [donationPage, setDonationPage] = useState(0);
  const [donationRowsPerPage, setDonationRowsPerPage] = useState(5);
  const [donationDateFilter, setDonationDateFilter] = useState('');
  const [donationEmailFilter, setDonationEmailFilter] = useState('');
  const [donationAmountFilter, setDonationAmountFilter] = useState('');

  useEffect(() => {
    fetchPayoutOperations();
    fetchTotalDonations();
    fetchDonations();
  }, [statusFilter, dateFilter, ibanFilter, donationDateFilter, donationEmailFilter, donationAmountFilter]);

  useEffect(() => {
    fetchPaypalPayouts();
  }, [paypalStatusFilter, paypalDateFilter, paypalEmailFilter]);

  const fetchPayoutOperations = async () => {
    try {
      const params = {
        iban: ibanFilter || undefined,
        status: statusFilter || undefined,
        date: dateFilter || undefined,
      };

      const response = await axios.get(`${apiUrl}/api/iban-payouts`, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPayouts(response.data);
    } catch (error) {
      setErrorMessage('Failed to load payout operations');
    }
  };

  const fetchPaypalPayouts = async () => {
    try {
      const params = {
        status: paypalStatusFilter || undefined,
        date: paypalDateFilter || undefined,
        email: paypalEmailFilter || undefined,
      };

      const response = await axios.get(`${apiUrl}/paypal`, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPaypalPayouts(response.data);
    } catch (error) {
      setErrorMessage('Failed to load PayPal payout operations');
    }
  };

  const fetchTotalDonations = async () => {
    try {
      const response = await axios.get(`${apiUrl}/admin/settings/total`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTotalDonations(response.data);
    } catch (error) {
      setErrorMessage('Failed to load total donations');
    }
  };

  const fetchDonations = async () => {
    try {
      const params = {
        date: donationDateFilter || undefined,
        email: donationEmailFilter || undefined,
        amount: donationAmountFilter || undefined,
      };

      const response = await axios.get(`${apiUrl}/api/donations`, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDonations(response.data);
    } catch (error) {
      setErrorMessage('Failed to load donations');
    }
  };

  const handleStatusChange = async (id, newStatus) => {
    try {
      await axios.put(
        `${apiUrl}/api/iban-payouts/${id}`,
        { status: newStatus },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      fetchPayoutOperations(); // Refresh the list after the status update
    } catch (error) {
      setErrorMessage('Failed to update status');
    }
  };

  const handleWithdraw = async () => {
    try {
      await axios.post(`${apiUrl}/admin/settings/withdrawDonations`, null, {
        params: { amount: withdrawAmount },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setWithdrawAmount(''); // Clear the input field
      fetchTotalDonations(); // Refresh the total donations after withdrawal
    } catch (error) {
      setErrorMessage('Failed to withdraw donations');
    }
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedPayouts = () => {
    return [...payouts].sort((a, b) => {
      if (orderBy === 'amount') {
        return (a.amount < b.amount ? -1 : 1) * (orderDirection === 'asc' ? 1 : -1);
      }
      if (orderBy === 'createdAt') {
        return (new Date(a.createdAt) < new Date(b.createdAt) ? -1 : 1) * (orderDirection === 'asc' ? 1 : -1);
      }
      return 0;
    });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePaypalPageChange = (event, newPage) => {
    setPaypalPage(newPage);
  };

  const handlePaypalRowsPerPageChange = (event) => {
    setPaypalRowsPerPage(parseInt(event.target.value, 10));
    setPaypalPage(0);
  };

  const handleDonationPageChange = (event, newPage) => {
    setDonationPage(newPage);
  };

  const handleDonationRowsPerPageChange = (event) => {
    setDonationRowsPerPage(parseInt(event.target.value, 10));
    setDonationPage(0);
  };

  return (
    <Container
      style={{
        maxWidth: '100%',
        margin: '10px auto', // Ajout d'espacement sous le header
        padding: '0 50px',
      }}
    >
      <Paper
        elevation={0}
        sx={{
          padding: '70px',
          borderRadius: '30px',
          width: '100%',
          margin: '0 auto',
        }}
      >
        <Typography variant="h4" gutterBottom>
          IBAN Payout Operations
        </Typography>
        <Box sx={{ mb: 3, display: 'flex', gap: 2 }}>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel>Status</InputLabel>
            <Select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)} label="Status">
              <MenuItem value="">All</MenuItem>
              <MenuItem value="PENDING">Pending</MenuItem>
              <MenuItem value="SUCCESS">Success</MenuItem>
              <MenuItem value="REJECTED">Rejected</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Filter by Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={dateFilter}
            onChange={(e) => setDateFilter(e.target.value)}
            sx={{ minWidth: 200 }}
          />
          <TextField
            label="Filter by IBAN"
            variant="outlined"
            value={ibanFilter}
            onChange={(e) => setIbanFilter(e.target.value)}
            sx={{ minWidth: 300 }}
          />
        </Box>
        <TableContainer component={Paper} sx={{ width: '100%' }}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: '#2196f3' }}>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>
                  <TableSortLabel
                    active={orderBy === 'id'}
                    direction={orderBy === 'id' ? orderDirection : 'asc'}
                    onClick={() => handleSortRequest('id')}
                  >
                    ID
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>
                  <TableSortLabel
                    active={orderBy === 'amount'}
                    direction={orderBy === 'amount' ? orderDirection : 'asc'}
                    onClick={() => handleSortRequest('amount')}
                  >
                    Amount
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>IBAN</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>Status</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>
                  <TableSortLabel
                    active={orderBy === 'createdAt'}
                    direction={orderBy === 'createdAt' ? orderDirection : 'asc'}
                    onClick={() => handleSortRequest('createdAt')}
                  >
                    Created At
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>Email</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>First Name</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>Last Name</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>Address</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>Zip Code</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedPayouts()
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((payout) => (
                  <TableRow key={payout.id}>
                    <TableCell>{payout.id}</TableCell>
                    <TableCell>{payout.amount}</TableCell>
                    <TableCell>{payout.iban}</TableCell>
                    <TableCell>{payout.status}</TableCell>
                    <TableCell>{format(new Date(payout.createdAt), 'yyyy-MM-dd HH:mm')}</TableCell>
                    <TableCell>{payout.userEmail}</TableCell>
                    <TableCell>{payout.userName}</TableCell>
                    <TableCell>{payout.userLastName}</TableCell>
                    <TableCell>{payout.userAddress}</TableCell>
                    <TableCell>{payout.userZipCode}</TableCell>
                    <TableCell>
                      {payout.status === 'PENDING' && (
                        <>
                          <Button variant="contained" color="success" onClick={() => handleStatusChange(payout.id, 'SUCCESS')} sx={{ mr: 1 }}>
                            Confirm
                          </Button>
                          <Button variant="contained" color="error" onClick={() => handleStatusChange(payout.id, 'REJECTED')}>
                            Reject
                          </Button>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={payouts.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            ActionsComponent={CustomPaginationActions}
          />
        </TableContainer>

        <Typography variant="h4" gutterBottom sx={{ mt: 6 }}>
          PayPal Payout Operations
        </Typography>
        
        <Box sx={{ mb: 3, display: 'flex', gap: 2 }}>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel>Status</InputLabel>
            <Select 
              value={paypalStatusFilter} 
              onChange={(e) => setPaypalStatusFilter(e.target.value)} 
              label="Status"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="PENDING">Pending</MenuItem>
              <MenuItem value="SUCCESS">Success</MenuItem>
              <MenuItem value="REJECTED">Rejected</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Filter by Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={paypalDateFilter}
            onChange={(e) => setPaypalDateFilter(e.target.value)}
            sx={{ minWidth: 200 }}
          />
          <TextField
            label="Filter by Email"
            variant="outlined"
            value={paypalEmailFilter}
            onChange={(e) => setPaypalEmailFilter(e.target.value)}
            sx={{ minWidth: 300 }}
          />
        </Box>

        <TableContainer component={Paper} sx={{ width: '100%', mt: 3 }}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: '#2196f3' }}>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>ID</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>Amount</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>Recipient Email</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>Status</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>Payout Batch ID</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>Created At</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>Email</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>First Name</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>Last Name</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>Address</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>Zip Code</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paypalPayouts
                .slice(paypalPage * paypalRowsPerPage, paypalPage * paypalRowsPerPage + paypalRowsPerPage)
                .map((payout) => (
                  <TableRow key={payout.id}>
                    <TableCell>{payout.id}</TableCell>
                    <TableCell>{payout.amount}</TableCell>
                    <TableCell>{payout.recipientEmail}</TableCell>
                    <TableCell>{payout.status}</TableCell>
                    <TableCell>{payout.payoutBatchId}</TableCell>
                    <TableCell>{format(new Date(payout.createdAt), 'yyyy-MM-dd HH:mm')}</TableCell>
                    <TableCell>{payout.userEmail}</TableCell>
                    <TableCell>{payout.userName}</TableCell>
                    <TableCell>{payout.userLastName}</TableCell>
                    <TableCell>{payout.userAddress}</TableCell>
                    <TableCell>{payout.userZipCode}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={paypalPayouts.length}
            rowsPerPage={paypalRowsPerPage}
            page={paypalPage}
            onPageChange={handlePaypalPageChange}
            onRowsPerPageChange={handlePaypalRowsPerPageChange}
            ActionsComponent={CustomPaginationActions}
          />
        </TableContainer>

        <Typography variant="h4" gutterBottom sx={{ mt: 6 }}>
          Donation Operations
        </Typography>
        
        <Box sx={{ mb: 3, display: 'flex', gap: 2 }}>
          <TextField
            label="Filter by Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={donationDateFilter}
            onChange={(e) => setDonationDateFilter(e.target.value)}
            sx={{ minWidth: 200 }}
          />
          <TextField
            label="Filter by Email"
            variant="outlined"
            value={donationEmailFilter}
            onChange={(e) => setDonationEmailFilter(e.target.value)}
            sx={{ minWidth: 300 }}
          />
          <TextField
            label="Filter by Amount"
            type="number"
            variant="outlined"
            value={donationAmountFilter}
            onChange={(e) => setDonationAmountFilter(e.target.value)}
            sx={{ minWidth: 200 }}
          />
        </Box>

        <TableContainer component={Paper} sx={{ width: '100%', mt: 3 }}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: '#2196f3' }}>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>ID</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>Amount</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>Created At</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>Email</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>First Name</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>Last Name</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>Address</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>Zip Code</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {donations
                .slice(donationPage * donationRowsPerPage, donationPage * donationRowsPerPage + donationRowsPerPage)
                .map((donation) => (
                  <TableRow key={donation.id}>
                    <TableCell>{donation.id}</TableCell>
                    <TableCell>{donation.amount}</TableCell>
                    <TableCell>{format(new Date(donation.createdAt), 'yyyy-MM-dd HH:mm')}</TableCell>
                    <TableCell>{donation.userEmail}</TableCell>
                    <TableCell>{donation.userName}</TableCell>
                    <TableCell>{donation.userLastName}</TableCell>
                    <TableCell>{donation.userAddress}</TableCell>
                    <TableCell>{donation.userZipCode}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={donations.length}
            rowsPerPage={donationRowsPerPage}
            page={donationPage}
            onPageChange={handleDonationPageChange}
            onRowsPerPageChange={handleDonationRowsPerPageChange}
            ActionsComponent={CustomPaginationActions}
          />
        </TableContainer>

        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

        <Box sx={{ mt: 5 }}>
          <Typography variant="h5" gutterBottom>
            Total Donations: {totalDonations.toFixed(2)} CHF
          </Typography>
          <TextField
            label="Withdraw Amount"
            type="number"
            variant="outlined"
            value={withdrawAmount}
            onChange={(e) => setWithdrawAmount(e.target.value)}
            sx={{ mr: 2, minWidth: 200 }}
          />
          <Button variant="contained" color="primary" onClick={handleWithdraw}>
            Withdraw
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default PayoutOperations;
