import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CircularProgress, Grid, Typography, Box } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { useAuth } from './auth';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

const UserEngagementCard = ({ userId }) => {
  const { token } = useAuth();
  const [dailyEngagement, setDailyEngagement] = useState(null);
  const [weeklyEngagement, setWeeklyEngagement] = useState(null);
  const [monthlyEngagement, setMonthlyEngagement] = useState(null);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_BASE_USER_SERVER;

  useEffect(() => {
    const fetchEngagementData = async () => {
      try {
        const [daily, weekly, monthly] = await Promise.all([
          axios.get(`${apiUrl}/api/engagement/user/${userId}/daily`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${apiUrl}/api/engagement/user/${userId}/weekly`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${apiUrl}/api/engagement/user/${userId}/monthly`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);
        
        setDailyEngagement(daily.data);
        setWeeklyEngagement(weekly.data);
        setMonthlyEngagement(monthly.data);
      } catch (error) {
        console.error('Error fetching engagement data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEngagementData();
  }, [userId, token, apiUrl]);

  if (loading) {
    return <CircularProgress />;
  }

  const createChartData = (engagement) => {
    if (!engagement) return [];
    return [
      { name: 'Answered', value: engagement.totalAnsweredQuestions },
      { name: 'Visible', value: engagement.totalVisibleQuestions },
      { name: 'Missed', value: engagement.totalMissedQuestions },
    ];
  };

  const renderEngagementChart = (data, title) => {
    const chartData = createChartData(data);
    const engagementRatio = (data?.engagementRatio || 0) * 100;

    return (
      <Box 
        sx={{ 
          p: 2, 
          backgroundColor: 'background.paper',
          borderRadius: 2,
          boxShadow: 1,
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Typography 
          variant="h6" 
          align="center" 
          gutterBottom
          sx={{ mb: 2 }}
        >
          {title}
        </Typography>
        <Box sx={{ width: '100%', height: 280, position: 'relative' }}>
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={chartData}
                cx="50%"
                cy="50%"
                innerRadius={50}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={3}
                dataKey="value"
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip 
                formatter={(value) => [`${value} questions`, '']}
              />
              <Legend 
                verticalAlign="bottom"
                height={36}
                iconSize={10}
                iconType="circle"
              />
            </PieChart>
          </ResponsiveContainer>
        </Box>
        <Typography 
          variant="h6" 
          color="primary" 
          align="center"
          sx={{ mt: 1 }}
        >
          {engagementRatio.toFixed(1)}%
        </Typography>
      </Box>
    );
  };

  return (
    <Grid 
      container 
      spacing={2}
      sx={{ 
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        p: 1
      }}
    >
      <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
        {renderEngagementChart(dailyEngagement, 'Daily')}
      </Grid>
      <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
        {renderEngagementChart(weeklyEngagement, 'Weekly')}
      </Grid>
      <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
        {renderEngagementChart(monthlyEngagement, 'Monthly')}
      </Grid>
    </Grid>
  );
};

export default UserEngagementCard;
